<template>
    <div class="home">
        <CarrotHeader></CarrotHeader>
        <div class="sub-wrap">
            <div class="sub-wrap-title">
                <p>고객사 관리</p>
            </div>
            


            <!-- 서브페이지 내용 -->
            <div class="con-wrap">
                <CarrotTitle title="스케줄 등록"></CarrotTitle>
                <div class="board">
                    <div class="mt-40">
                        <div>
                            <table class="table-row">
                                <colgroup>
                                    <col width="180">
                                    <col width="*">
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <th><span class="txt-red">*</span> 일시</th>
                                        <td>
                                            <input type="text" class="w-80px"> ~
                                            <input type="text" class="w-80px mr-20">
                                            <CarrotTimeSelect></CarrotTimeSelect>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th><span class="txt-red">*</span> 목적</th>
                                        <td>
                                            <input type="radio" id="work_reason" value="OP"><span class="ml-05 mr-20"> 오픈</span>
                                            <input type="radio" id="work_reason" value="OV"><span class="ml-05 mr-20"> 참관</span>
                                            <input type="radio" id="work_reason" value="CM"><span class="ml-05 mr-20"> 합숙</span>
                                            <input type="radio" id="work_reason" value="TJ"><span class="ml-05 mr-20"> 타지</span>
                                            <input type="radio" id="work_reason" value="WE"><span class="ml-05 mr-20"> 주말</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th><span class="txt-red">*</span> 고객사</th>
                                        <td>
                                            <input type="text" class="w-400px">
                                            <span>* 입력 시 나오는 고객사명에서 선택해주세요. </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th><span class="txt-red">*</span> 상세내용</th>
                                        <td>
                                            <textarea class="w-100per h-100px"></textarea>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th><span class="txt-red">*</span> 상세내용</th>
                                        <td>
                                            <textarea class="w-100per h-100px"></textarea>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <router-link to="/MypimsPersonalityList"><button class="btn-default float-left mt-30">취소</button></router-link>
                            <router-link to="/MypimsPersonalityList"><button class="btn-default float-right mt-30">등록</button></router-link>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 서브페이지 내용 끝 -->
        </div>
    </div>
</template>

<script>
// @ is an alias to /src


export default {
}
</script>

<style lang="scss" scoped>
</style>